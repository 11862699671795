import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import LayersIcon from '@mui/icons-material/Layers';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import PublicIcon from '@mui/icons-material/Public';
import InventoryIcon from '@mui/icons-material/Inventory';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import GrassIcon from '@mui/icons-material/Grass';
import CloudIcon from '@mui/icons-material/Cloud';
import { Paper } from '@mui/material';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';

import background from "./background.gif";


const TOKEN = process.env.REACT_APP_TOKEN;

function FirstPage() {
    const navigate = useNavigate();

    const [markers, setMarkers] = useState([{ lat: 4.2507605, lng: 117.8912846 }, { lat: 4.2507405, lng: 117.8912846 }, { lat: 4.2507605, lng: 117.8912836 }, { lat: 4.2507705, lng: 117.8912846 }])
    const [openBlockInfo, setOpenBlockInfo] = useState(false)
    const [triangleCoords, setTriangleCoords] = useState([
        { lat: 25.774, lng: -80.190 },
        { lat: 18.466, lng: -66.118 },
        { lat: 32.321, lng: -64.757 },
        { lat: 25.774, lng: -80.190 }
    ])

    const [mapCenter, setMapCenter] = useState({
        lat: 4.2507605,
        lng: 117.8912846


    })


    useEffect(() => {



        newCenter()


    }, []);


    const newCenter = () => {
        let newObj = [];
        let lat = 4.2507605;
        let lng = 117.8912846;
        for (let i = 0; i < 50000; i++) {
            lat = 4.2507605 + (Math.random() * 10) + (-5);
            lng = 117.8912846 + (Math.random() * 10) + (-5);
            newObj.push({ lat: lat, lng: lng })
        }
        //setMarkers(newObj)
        // setTimeout(() => {
        //     setMapCenter({ lat: 5.2507605, lng: 117.8912846 })
        // }, 3000);
    }

    const navigateToSecondPage = (page) => {
        navigate(page);
    };

    const onpressHandlerOpenBlockInfo = (value) => {
        setOpenBlockInfo(value)
    }

    return (


        <div style={{
            position: 'relative',
            width: '100%',
            height: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: '#fff', alignItems: 'center',
            backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundAttachment: 'fixed', backgroundPosition: 'center'
        }}>

            <div style={{
                position: 'relative',
                width: '100%',
                height: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: '#fff', alignItems: 'center',
                backgroundColor: '#eeeeee88'
            }}>

                <div style={{ padding: 5, marginTop: 10, height: 80, cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <span style={{ color: '#333', fontSize: 30, fontWeight: 600 }}>Teck Guan Web Application</span>
                </div>
                <div style={{ height: 30 }}></div>

                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 25, marginBottom: 25 }}>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginInline: 25 }}>
                        <Paper style={{ width: 90, height: 90, backgroundColor: '#5DADE2', borderRadius: 30, cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            onClick={() => window.location.replace('https://www.teckguan.com/TGBiS')}>
                            <BusinessCenterIcon sx={{ fontSize: 50, color: '#fff' }} />
                        </Paper>
                        <span style={{ color: '#333', fontSize: 14, fontWeight: 600 }}>TGBiS</span>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginInline: 25 }}>

                        <Paper elevation={2} style={{ width: 90, height: 90, backgroundColor: '#5DADE2', borderRadius: 30, cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            onClick={() => window.location.replace('https://tgapps.synology.me:9119')}>
                            <CloudIcon sx={{ fontSize: 50, color: '#fff' }} />
                        </Paper>

                        <span style={{ color: '#333', fontSize: 14, fontWeight: 600 }}>GTS</span>

                    </div>



                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginInline: 25 }}>
                        <Paper style={{ width: 90, height: 90, backgroundColor: '#5DADE2', borderRadius: 30, cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            onClick={() => window.location.replace('https://tgapps.synology.me:43355/')}>
                            <CurrencyExchangeIcon sx={{ fontSize: 50, color: '#fff' }} />
                        </Paper>
                        <span style={{ color: '#333', fontSize: 14, fontWeight: 600 }}>MCS</span>
                    </div>


                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginInline: 25 }}>
                        <Paper style={{ width: 90, height: 90, backgroundColor: '#5DADE2', borderRadius: 30, cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            onClick={() => window.location.replace('https://tgapps.synology.me:7774/')}>
                            <RequestQuoteIcon sx={{ fontSize: 50, color: '#fff' }} />
                        </Paper>
                        <span style={{ color: '#333', fontSize: 14, fontWeight: 600 }}>BMS</span>
                    </div>



                </div>

                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 25, marginBottom: 25 }}>



                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginInline: 25 }}>
                        <Paper style={{ width: 90, height: 90, backgroundColor: '#5DADE2', borderRadius: 30, cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            onClick={() => window.location.replace('https://tgapps.synology.me:45582/rainfall')}>
                            <InventoryIcon sx={{ fontSize: 50, color: '#fff' }} />
                        </Paper>
                        <span style={{ color: '#333', fontSize: 14, fontWeight: 600 }}>Rainfall</span>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginInline: 25 }}>
                        <Paper style={{ width: 90, height: 90, backgroundColor: '#dcf1ff', borderRadius: 30, cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            onClick={() => window.location.replace('/')}>
                            <BlurOnIcon sx={{ fontSize: 50, color: '#fff' }} />
                        </Paper>
                        <span style={{ color: '#333', fontSize: 14, fontWeight: 600 }}>BBC</span>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginInline: 25 }}>
                        <Paper style={{ width: 90, height: 90, backgroundColor: '#dcf1ff', borderRadius: 30, cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            onClick={() => window.location.replace('/')}>
                            <BlurOnIcon sx={{ fontSize: 50, color: '#fff' }} />
                        </Paper>
                        <span style={{ color: '#333', fontSize: 14, fontWeight: 600 }}>Manuring</span>
                    </div>


                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginInline: 25 }}>
                        <Paper style={{ width: 90, height: 90, backgroundColor: '#5DADE2', borderRadius: 30, cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            onClick={() => window.location.replace('https://tgapps.synology.me:45582/gismap/map')}>
                            <PublicIcon sx={{ fontSize: 50, color: '#fff' }} />
                        </Paper>
                        <span style={{ color: '#333', fontSize: 14, fontWeight: 600 }}>GISMap</span>
                    </div>


                </div>




            </div>


        </div>


    );
}

export default FirstPage;

